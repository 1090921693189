.header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
}

.name-container {
  display: flex;
  text-align: center;
  justify-content: center;
}

.name-header {
  color: var(--main-text-color);
  transition: color 0.5s ease;
  font-family: "RobotoSlab-Bold";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 23px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* font-size: 115px; */
  font-size: 6vw;
}

.background-box {
  /* height: 35px; */
  width: 100%;
  height: 2.2vw;
  flex-shrink: 0;
  background-color: var(--title-dash-color);
  transition: background-color 0.5s ease;
  position: absolute;
  left: 50%;
  align-self: center;
  transform: translate(-50%, 0);
}

.about-me-background,
.my-interests-background {
  height: 1.6vw;
}

.job-subtitle {
  color: var(--main-text-color);
  transition: color 0.5s ease;

  font-family: "RobotoSlab-Regular";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 5.4px;
  text-align: center;
  margin-top: 1px;
}

.description {
  color: var(--main-text-color);
  transition: color 0.5s ease;

  text-align: center;
  font-family: "RobotoSlab-Light";
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 3px;
  max-width: 60%;
  align-self: center;
  margin-top: 18px;
}

.outer-link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
}

.link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 18px;
  margin-right: 18px;
  cursor: pointer;
  transition: transform 0.3s ease;
  text-decoration: none;
}

.link-container:hover {
  transform: scale(1.1);
}

.link-container.hover {
  transform: scale(1.1);
}

.link-item {
  color: var(--main-text-color);
  transition: color 0.5s ease;

  font-family: "RobotoSlab-Light";
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-left: 14px;
}

@media only screen and (max-width: 1000px) {
  .name-header {
    letter-spacing: 15px;
  }

  .background-box {
    height: 2.2vw;
  }

  .job-subtitle {
    font-size: 2.2vw;
    letter-spacing: 5.4px;
  }

  .description {
    max-width: 70%;
    font-size: 13px;
  }
}

@media only screen and (max-width: 720px) {
  .name-header {
    font-size: 36px;
    letter-spacing: 10px;
  }

  .job-subtitle {
    font-size: 15px;
  }

  .background-box {
    height: 2.2vw;
  }

  .description {
    max-width: 80%;
    font-size: 13px;
  }

  .name-container {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .name-header {
    letter-spacing: 7px;
  }

  .job-subtitle {
    font-size: 15px;
  }

  .description {
    max-width: 100%;
    font-size: 13px;
  }
}

@media only screen and (max-width: 500px) {
  .name-header {
    font-size: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .name-header {
    font-size: 30px;
  }

  .outer-link-container {
    flex-direction: column;
  }

  .link-container {
    margin-top: 8px;
  }
}

@media only screen and (max-width: 405px) {
  .name-header {
    font-size: 25px;
  }

  .job-subtitle {
    font-size: 13px;
  }

  .description {
    max-width: 100%;
    font-size: 11px;
  }
}

@media only screen and (max-width: 370px) {
  .name-header {
    font-size: 22px;
  }

  .job-subtitle {
    font-size: 11px;
  }

  .description {
    max-width: 100%;
    font-size: 10px;
  }
}

@media only screen and (max-width: 345px) {
  .name-header {
    font-size: 17px;
  }
}
