.arrow-container {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  color: var(--main-text-color);

  font-family: "RobotoSlab-Regular";
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3px;

  flex-shrink: 1;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.5s ease;
  user-select: none;
  z-index: 1;
}

.arrow-container div,
.arrow-container text {
  transition: transform 0.3s ease;
}

.arrow-container.left {
  transform-origin: left center;
  transform: rotate(90deg) translateX(-50%) translateY(-10px);
  left: 0px;
}

.arrow-container.right {
  transform-origin: right center;
  transform: rotate(-90deg) translateX(50%) translateY(-10px);
  right: 0px;
}

.arrow-container.top {
  transform: rotate(180deg) translateY(50%);
  top: 0px;
}

.arrow-container.bottom {
  bottom: 0px;
}

.arrow-container:hover text {
  transform: scale(1.2) !important;
}
.arrow-container.hover text {
  transform: scale(1.2) !important;
}

.icon-container {
  position: absolute;
  transform: translateY(28px);
}
.arrow-container:hover .icon-container {
  transform: scale(1.2) translateY(28px) !important;
}

.clickable-area.horizontal {
  width: calc(100vw + 100vh);
  height: 91px;
  position: absolute;
  top: 0;
}

.clickable-area.vertical {
  width: calc(100vw + 100vh);
  height: 91px;
  position: absolute;
  top: 0;
}

@media only screen and (max-width: 1300px) {
  .arrow-container.left,
  .arrow-container.right {
    display: none;
  }
}

@media only screen and (max-width: 720px) {
  .arrow-container.top {
    margin-top: 31px !important;
  }
}

@media only screen and (max-height: 720px) {
  .arrow-container.left,
  .arrow-container.right {
    display: none;
  }

  .arrow-container.top {
    margin-top: 21px !important;
  }
}
