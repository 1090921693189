.full-journey-container {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.journey-full-item-container {
  display: flex;
  position: relative;
  margin-bottom: 60px;
  margin-top: 71px;
  transition: transform 0.3s ease, opacity 0.5s ease;
  opacity: 0.7;
}

/* .journey-full-item-container:hover {
  opacity: 1;
  transform: scale(1.01);
} */

.journey-full-item-container.hover {
  opacity: 1;
  transform: scale(1.01);
}

.journey-full-item-container.left {
  flex-direction: row-reverse;
}

.column-journey-container {
  display: flex;
  flex-direction: column;
  width: calc(40%);
}

.column-journey-container.right {
  margin-top: calc(71px + 10%);
}

.journey-item-container {
  display: flex;
  flex-direction: column;
}

.journey-item-container.left {
  align-items: flex-end;
  text-align: right;
}

.journey-item-container.right {
  align-items: flex-start;
  text-align: left;
}

.journey-item-title {
  text-align: inherit;
  color: var(--main-text-color);
  transition: color 0.5s ease;

  font-family: "RobotoSlab-Bold";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 5.4px;
  flex-shrink: 0;
}

.journey-item-desc {
  margin-top: 10px;
  text-align: inherit;
  color: var(--main-text-color);
  transition: color 0.5s ease;

  font-family: "RobotoSlab-Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 2.6px;
}

.horizontal-divider {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 1px;
  background-color: var(--footstep-color);
  transition: background-color 0.5s ease;
}

.vertical-divider {
  margin-left: 20px;
  margin-right: 20px;
  width: 1.3px;
  height: 100%;
  background-color: var(--footstep-color);
  transition: background-color 0.5s ease;
}

.journey-item-skills {
  text-align: inherit;
  color: var(--main-text-color);
  transition: color 0.5s ease;

  font-family: "RobotoSlab-Thin";
  font-size: 12px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  letter-spacing: 2px;
}

.journey-item-image-container {
  display: flex;
  margin-top: 10px;
}

.journey-item-image-container.left {
  display: flex;
  flex-direction: row-reverse;
}

.center-divider {
  width: 1px;
  background-color: transparent;
}

.journey-item-duration-space {
  min-width: 15px;
  width: 15px;
}

.journey-item-duration {
  position: absolute;
  color: var(--main-text-color);
  transition: color 0.5s ease;

  text-align: center;
  font-family: "RobotoSlab-Regular";
  font-size: 12px;
  max-height: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  flex-shrink: 1;
  top: 50%;
}

.journey-item-duration.left {
  transform: rotate(90deg) translateX(50%) translateY(50%);
  transform-origin: center right;
}

.journey-item-duration.right {
  transform: rotate(-90deg) translateX(-50%) translateY(50%);
  transform-origin: center left;
}

.journey-arrow-container {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
}

.return-top-text {
  color: var(--instruction-color);

  font-family: "RobotoSlab-Regular";
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3px;

  flex-shrink: 1;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.5s ease;
  user-select: none;
  z-index: 1;
  margin: 31px;
  margin-top: 10px;
}

.return-top-text:hover {
  transform: scale(1.2) !important;
}
.return-top-text.hover {
  transform: scale(1.2) !important;
}

@media only screen and (max-width: 1400px) {
  .journey-item-title {
    font-size: 25px;
  }

  .journey-item-desc {
    font-size: 13px;
  }

  .vertical-divider {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1100px) {
  .left {
    display: none;
  }

  .journey-item-title {
    font-size: 25px;
  }

  .journey-item-desc {
    font-size: 13px;
  }

  .center-divider {
    display: none;
  }

  .right {
    width: auto;
    max-width: 100%;
  }

  .column-journey-container.right {
    margin-top: calc(30px + 10%) !important;
  }

  .journey-full-item-container {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .vertical-divider {
    margin-left: 10px;
    margin-right: 10px;
  }
}
