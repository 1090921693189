@font-face {
  font-family: "RobotoSlab-Regular";
  src: local("RobotoSlab-Regular"),
    url(./styles/fonts/RobotoSlab-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoSlab-Bold";
  src: local("RobotoSlab-Bold"),
    url(./styles/fonts/RobotoSlab-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoSlab-Light";
  src: local("RobotoSlab-Light"),
    url(./styles/fonts/RobotoSlab-Light.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoSlab-Thin";
  src: local("RobotoSlab-Thin"),
    url(./styles/fonts/RobotoSlab-Thin.ttf) format("truetype");
}

body {
  overflow: hidden;
  background-color: var(--main-bg-color);
  display: flex;
  font-family: "RobotoSlab-Regular";
  overflow: hidden;
}

.outer-frame {
  background-color: var(--main-bg-color);
  transition: background-color 0.5s ease;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "RobotoSlab-Regular";
  overflow: hidden;
}

#loader-container {
  background-color: var(--main-bg-color);
  transition: background-color 0.5s ease;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}

.frame {
  background-color: var(--main-bg-color);
  transition: background-color 0.5s ease;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  font-family: "RobotoSlab-Regular";
  overflow: hidden;
}

.background {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  font-family: "RobotoSlab-Regular";
  overflow: hidden;
  transition: transform 0.1s ease;
  min-height: 500px;
}

.screen {
  width: 100vw;
  height: 100dvh;
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.5s ease;
}

.screen.right {
  transform: translateX(100vw);
}

.screen.left {
  transform: translateX(-100vw);
}

.screen.top {
  transform: translateY(-100vh);
}

.screen.bottom {
  transform: translateY(100vh);
}

.bordered-frame {
  margin: 71px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.content-container {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.interests-title-container {
  transform: rotate(-90deg) translateY(calc(50% - 71px)) translateX(50%);
  position: absolute;
  right: 0px;
  transform-origin: right center;
}

.svg-icon {
  fill: var(--icon-color);
  transition: fill 0.5s ease;
}

.email-fill-icon {
  fill: var(--icon-color);
  transition: fill 0.5s ease;
}

.email-stroke-icon {
  stroke: var(--icon-color);
  transition: stroke 0.5s ease;
}

.arrow-icon {
  stroke: var(--main-text-color);
  transition: stroke 0.5s ease;
}

.footstep-icon {
  fill: var(--footstep-color);
  transition: fill 0.5s ease;
}

.instruction-icon {
  fill: var(--instruction-color);
  transition: fill 0.5s ease;
}

.instruction-icon-bg {
  fill: var(--main-bg-color);
  transition: fill 0.5s ease;
}

.instruction-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 500px;
  transform: translateX(-240px) translateY(-25px);
}

.instruction-line {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.instruction-text {
  color: var(--footstep-color);
  font-family: "RobotoSlab-Regular";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3px;
  margin-left: 10px;
  margin-right: 10px;
  transition: color 0.5s ease;
  user-select: none;
}

.instruction-side {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.instruction-side.left {
  justify-content: flex-end;
}

.instruction-side.right {
  justify-content: flex-start;
}

@media only screen and (max-width: 1300px) {
  #interests-screen,
  #about-screen {
    display: none;
  }

  .instruction-container {
    display: none;
  }
}

@media only screen and (max-height: 700px) {
  #interests-screen,
  #about-screen {
    display: none;
  }

  .instruction-container {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .instruction-container {
    width: auto;
  }

  .bordered-frame {
    padding: 51px !important;
    margin: 51px !important;
  }
}

@media only screen and (max-width: 850px) {
  .instruction-container {
    width: auto;
  }

  .bordered-frame {
    padding: 39px !important;
    margin: 39px !important;
  }
}

@media only screen and (max-width: 720px) {
  .bordered-frame {
    padding: 21px !important;
    margin: 21px !important;
  }
}

@media only screen and (max-width: 575px) {
}
