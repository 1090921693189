.interest-container {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  z-index: 2;
  transition: transform 0.3s ease;
}

.interest-container:hover {
  transform: scale(1.1);
}

.interest-container.hover {
  transform: scale(1.1);
}

.interest-title {
  color: var(--main-text-color);
  transition: color 0.5s ease;

  font-family: "RobotoSlab-Regular";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 5.4px;
}

.interest-description {
  flex-shrink: 0;
  color: var(--main-text-color);
  transition: color 0.5s ease;

  font-family: "RobotoSlab-Light";
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 2.6px;
}

.interest-image-container {
  display: flex;
  margin-top: 4px;
}

.interest-image-inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.interest-image-timer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.interest-image {
  object-fit: cover;
}

.content-container {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.interest-list-container {
  flex-grow: 1;
  margin-left: 150px;
  margin-right: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}

.single-interest-container {
  display: flex;
  flex-direction: row;
  width: 80%;
}

.single-interest-container.left {
  justify-content: flex-start;
}

.single-interest-container.right {
  justify-content: flex-end;
}
